import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Member, User } from 'src/models';
import { Organization } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  public loggedUser: User;
  public currentMember: Member;
  memberSub: BehaviorSubject<Member> = new BehaviorSubject(undefined);

  endpoint = environment.endpoint();
  uri = environment.uri();
  _urlLogin = '/login';
  _urlLogout = '/logout';
  _urlUseInvite = '/organization/use-invite';
  _urlUser = '/user';
  _urlPassRecovery = '/password-recovery';
  _urlChangePassword = '/change-password';
  _urlGetMember = '/member';
  _urlUploadImage = '/user/:id/image';
  _urlUpdateSuperAdmin = '/master/members/super-admins';
  _urlUpdateOrganizationAdmin = '/master/organizations/:id'

  constructor(private _http: HttpClient, private _route: Router) { }

  // Método de login
  login(user) {
    const url = this.endpoint + this._urlLogin;
    return this._http.post(url, user).pipe(
      tap(
        (data) => {
          this.doLoginUser(data);
        },
        (error) => {
          console.log(error.error);
        },
      ),
    );
  }

  // Método de logout
  logout() {
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getJwtToken()}`,
    });
    const url = this.endpoint + this._urlLogout;

    // Verifica se o usuário está logado antes de deslogar
    if (this.isLoggedIn()) {
      this._http.post(url, { token: this.getJwtToken() }, { headers: reqHeaders }).subscribe(
        () => {
          this.doLogoutUser();
          location.reload();

        },
        (error) => {
          alert(error.error);
        },
      );
    }
  }

  //Verifica se o usuário está logado
  isLoggedIn() {
    if (this.getJwtToken()) {
      return true;
    } else {
      return false;
    }
  }

  //Retorna os dados do usuário atual
  currentUser() {
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getJwtToken()}`,
    });

    const url = this.endpoint + this._urlUser;
    return this._http.get<User>(url, { headers: reqHeaders });
  }

  // Handle login
  doLoginUser(data, workspace?) {
    this.loggedUser = data.user;
    this.storeToken(data.token);
    this.setCurrentMember();
    if (!workspace) {
      this._route.navigate(['/onboarding/welcome']);
    } else {
      this._route.navigate(['/']);
    }
  }

  // Salvar tokens no localstorage
  storeToken(token: string) {
    localStorage.setItem(this.JWT_TOKEN, token);
  }

  // Retorna o JWT token do localstorage
  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  // handle logout
  private doLogoutUser() {
    this.removeToken();
    this.loggedUser = null;
    this._route.navigate(['/login']);
  }

  // Limpa os tokens
  private removeToken() {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  // Cria senha para primeiro acesso pelo link do email e faz login
  public useInvite(accessCode, password) {
    const url = this.endpoint + this._urlUseInvite;
    var resToken;
    return this._http.post(url, { accessCode: accessCode, password: password }).pipe(
      tap(
        (res) => {
          resToken = res;
          this.removeToken();
          this.storeToken(resToken.token);
        },
        (error) => {
          console.log(error);
        },
      ),
    );
  }

  // POST envio do e-mail "esqueci minha senha"
  forgotPassword(email) {
    const url = this.endpoint + this._urlPassRecovery;
    return this._http.post(url, { email: email, originUrl: this.uri });
  }

  // Edit usuário com método PUT
  editUser(form) {
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getJwtToken()}`,
    });

    const url = this.endpoint + this._urlUser;
    return this._http.put(url, form, { headers: reqHeaders });
  }

  // Trocar Senha, edição de perfil
  changePassword(pass, newPass) {
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.getJwtToken()}`,
    });
    const url = this.endpoint + this._urlUser + this._urlChangePassword;

    return this._http.put(url, { password: pass, newPassword: newPass }, { headers: reqHeaders });
  }

  // PUT mudar a senha
  changeFgtPassword(token: string, password: string) {
    const url = this.endpoint + this._urlPassRecovery + this._urlChangePassword;
    return this._http.put(url, { token: token, password: password });
  }

  // persiste membro no serviço
  setCurrentMember() {
    const url = this.endpoint + this._urlGetMember
    this._http.get(url).subscribe((member: Member) => {
      this.currentMember = member;
      this.memberSub.next(member);
    })
  }

  uploadImages(file: File, userId: string) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this.getJwtToken()}`,
      FormData: 'true',
    });
    const formData = new FormData();
    formData.append('file', file, file.name);
    const url = this.endpoint + this._urlUploadImage.replace(':id', userId);
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  updateSuperAdmins(membersSuper) {
    const url = this.endpoint + this._urlUpdateSuperAdmin;
    return this._http.put(url, membersSuper);
  }

  updateOrganizationAdmin(org: Organization) {
    const url = this.endpoint + this._urlUpdateOrganizationAdmin.replace(':id', org.id);
    return this._http.put(url, org)
  }

}
