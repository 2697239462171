import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { svgElement } from 'src/dictionaries/svg-sign';
import {
  ActionEnum,
  MemberRoleEnum,
  OccurrenceStatusEnum,
  PendingStatusEnum,
  SignalingImageSituationEnum,
  SignalingTypeEnum,
  UserDutyEnum,
  WarrantyStatusEnum,
} from 'src/enumerators';
import { environment } from 'src/environments/environment';
import { DeviceSignaling, HorizontalSignaling, TrafficSign, VerticalCombinedSign, VerticalSignaling } from 'src/models';
import { SignalingFilterCost, SignalingFilterRes, SignalingList, VerticalTrafficSignaling } from 'src/models/signaling';
import { AuthService } from './auth.service';
import { RestApiService } from './rest-api.service';
import { endOfDay, startOfDay } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class CatalogService extends RestApiService {
  environmentEndpoint = environment.endpoint();

  currentSign = [];
  signs = [];
  editableSigns: Array<any> = [];

  _urlGetAllVertical = '/vertical-signalings';
  _urlGetAllHorizontal = '/horizontal-signalings';
  _urlGetAllDevices = '/device-signalings';
  _urlGetTrafficSign = '/traffic-signs/:id';
  _urlGetTrafficSignsByArray = '/traffic-signs?ids=:ids';
  _urlGetHorizontalSignsByArray = '/horizontal-signalings?ids=:ids';
  _urlGetDeviceSignsByArray = '/device-signalings?ids=:ids';
  _urlUploadFilesVertical = '/vertical-signalings/:id/files';
  _urlHorizontalFilesVertical = '/horizontal-signalings/:id/files';
  _urlDeviceFilesVertical = '/device-signalings/:id/files';
  _urlDownloadVerticalFiles = '/vertical-signalings/files';
  _urlDownloadHorizontalFiles = '/horizontal-signalings/files';
  _urlDownloadDeviceFiles = '/device-signalings/files';
  _urlRemoveVerticalImage = '/vertical-signalings/files/:fileId';
  _urlRemoveHorizontalImage = '/horizontal-signalings/files/:fileId';
  _urlRemoveDeviceImage = '/device-signalings/files/:fileId';
  _urlDeleteVerticalCombined = '/traffic-signs/remove-collection';
  _urlDeleteAllVertical = '/vertical-signalings/remove-collection';
  _urlDeleteAllHorizontal = '/horizontal-signalings/remove-collection';
  _urlDeleteAllDevices = '/device-signalings/remove-collection';
  _urlGetAllVerticalFromProject = '/vertical-signalings?projectId=:id';
  _urlGetAllHorizontalFromProject = '/horizontal-signalings?projectId=:id';
  _urlGetAllDevicesFromProject = '/device-signalings?projectId=:id';
  _urlUserSignalignsHistory = '/user-signaling-history?queryParams';
  _urlGetSignalingHistory = '/user-signaling-history';
  _urlUpdateStatus = '/update-status';
  _urlGetSignaling = '/signalings';
  _urlGetSignalingSummarized = '/signalings/summarized';
  _urlGetSignalingData = '/signalings/data';
  _urlGetSignalingCost = '/signalings/data/cost';
  _urlMemorial = '/signalings/calculation-memory-report';
  _urlExpiredSignalings = '/signalings?expiredWarranty=true';
  _urlDashboardCatalog = '/dashboard/signalings'

  constructor(private _http: HttpClient, private _auth: AuthService, private _sanitizer: DomSanitizer) {
    super(_http);
  }

  createFilterUrl(
    url: string,
    checkOptionsFilter?: Array<any>,
    warrantyStatus?: Array<WarrantyStatusEnum>,
    signalingTypes?: Array<SignalingTypeEnum>,
    offSet?: number,
    limit?: number,
    summarized?: boolean,
    retroreflectionStatus?: boolean,
    status?: Array<PendingStatusEnum>,
    supportItensFilter?: Array<any>,
    occurrenceStatus?: Array<OccurrenceStatusEnum>,
    files?: boolean,
  ): string {
    let listJson;
    // Variavel que sabe se tem algum item de alguns dos tipos HVD
    let itemV = false;
    let itemH = false;
    let itemD = false;

    if (offSet != undefined && limit != undefined && offSet >= 0 && limit >= 0) {
      url += `offset=${offSet}&limit=${limit}&`;
    }
    if (checkOptionsFilter.length) {
      checkOptionsFilter.forEach((filter: any) => {
        if (filter.selected.length > 0) {
          if (filter.value == 'Contrato') {
            listJson = JSON.stringify(filter.selected);
            url += `contractIds=${listJson}&`;
          }

          else if (filter.value == 'Vínculo Secundário') {
            listJson = JSON.stringify(filter.selected);
            url += `projectIds=${listJson}&`;
          }

          else if (filter.value == 'Região') {
            if (filter.selected[0] == '0') {
              url += `noRegions=true&`;
            } else {
              listJson = JSON.stringify(filter.selected);
              url += `regionIds=${listJson}&`;
            }
          }

          else if (filter.value == 'Área') {
            if (filter.selected[0] == '0') {
              url += `noAreas=true&`;
            } else {
              listJson = JSON.stringify(filter.selected);
              url += `areaIds=${listJson}&`;
            }
          }

          else if (filter.value == 'Intervalo de Tempo') {
            listJson = JSON.stringify(filter.selected);
            let s = filter.selected[0][0] ? new Date(new Date(filter.selected[0][0]).setHours(-3, 0, 0)) : undefined;
            let e = filter.selected[0][1] ? new Date(new Date(filter.selected[0][1]).setHours(20, 59, 59)) : undefined;
            const start = `startDate=${s.toISOString()}&`;
            const end = `endDate=${e.toISOString()}&`;
            url += start;
            url += end;
          }

          else if (filter.value == 'Responsável') {
            listJson = JSON.stringify(filter.selected);
            url += `responsibleMemberIds=${listJson}&`;
          }

          else if (filter.value == 'Endereço') {
            let suburb;
            let road;
            if (filter.selected[0].type == 'suburb') suburb = filter.selected[0].name;
            else if (filter.selected[0].type == 'road') road = filter.selected[0].name;
            const addressJson = JSON.stringify({ road, suburb });
            url += `address=${addressJson}&`;
          }

          else if (filter.value == 'Intervalo de Tempo de Manutenção') {

            let start = filter.selected[0][0] ? new Date(new Date(filter.selected[0][0]).setHours(-3, 0, 0)) : undefined;
            let end = filter.selected[0][1] ? new Date(new Date(filter.selected[0][1]).setHours(20, 59, 59)) : undefined;
            const startM = `startMaintenanceDate=${start.toISOString()}&`;
            const endM = `endMaintenanceDate=${end.toISOString()}&`;
            url += startM;
            url += endM;
          }

          else if (filter?.title == 'Sinalizações Verticais') {
            itemV = true;
            const verticalCodes = JSON.stringify(filter.selected);
            url += `verticalCodes=${verticalCodes}&`;
          }

          else if (filter?.title == 'Sinalizações Horizontais') {
            itemH = true;
            const horizontalCodes = JSON.stringify(filter.selected);
            url += `horizontalCodes=${horizontalCodes}&`;
          }

          else if (filter?.title == 'Dispositivos Auxiliares') {
            itemD = true;
            const deviceCodes = JSON.stringify(filter.selected);
            url += `deviceCodes=${deviceCodes}&`;
          }

          else if (filter?.name == 'Itens do Contrato de Vertical') {
            let verticalJson;
            itemV = true;
            if (supportItensFilter) {
              verticalJson = JSON.stringify(filter.selected.filter(elemento => !supportItensFilter?.includes(elemento)));
              if (supportItensFilter.length) {
                const supportJson = JSON.stringify(supportItensFilter);
                url += `contractSupportItemIds=${supportJson}&`;
              }
            }
            else verticalJson = JSON.stringify(filter.selected)
            if (filter.selected.filter(elemento => !supportItensFilter?.includes(elemento)).length) {
              url += `contractVerticalItemIds=${verticalJson}&`;
            }
          }

          else if (filter?.name == 'Itens do Contrato de Horizontal') {
            itemH = true;
            listJson = JSON.stringify(filter.selected);
            url += `contractHorizontalItemIds=${listJson}&`;
          }

          else if (filter?.name == 'Itens do Contrato de Dispositivos') {
            itemD = true;
            listJson = JSON.stringify(filter.selected);
            url += `contractDeviceItemIds=${listJson}&`;
          }

          else if (filter?.value == 'Ocorrências') {
            listJson = JSON.stringify(filter.selected);
            url += `occurrenceStatus=${listJson}&`;
          }

          else if (filter?.value == 'Suportes') {
            if (filter.selected.find(i => i == 'allSupport')) {
              url += `support=true&`;
            } else if (filter.selected[0] == '0') {
              url += `noSupport=true&`;
            } else {
              listJson = JSON.stringify(filter.selected);
              url += `supportMaterialType=${listJson}&`;
            }
          }

        }
      });
    }

    if (warrantyStatus?.length) {
      listJson = JSON.stringify(warrantyStatus);
      url += `warrantyStatus=${listJson}&`;
    }

    if (retroreflectionStatus) url += `belowMinimumRetroreflection=${retroreflectionStatus}&`;

    if (itemV || itemH || itemD) {
      signalingTypes = [];
      if (itemV) signalingTypes.push(SignalingTypeEnum.Vertical);
      if (itemH) signalingTypes.push(SignalingTypeEnum.Horizontal);
      if (itemD) signalingTypes.push(SignalingTypeEnum.Device);
    }

    if (signalingTypes && (signalingTypes.length > 0)) {
      const signalingTypesJson = JSON.stringify(signalingTypes);
      url += `signalingTypes=${signalingTypesJson}&`;
    }

    if (status) {
      const statusJson = JSON.stringify(status);
      url += `status=${statusJson}&`;
    }

    if (files) url += 'files=true&'

    return url;
  }

  getSignaling(
    checkOptionsFilter?: Array<any>,
    warrantyStatus?: Array<WarrantyStatusEnum>,
    signalingTypes?: Array<SignalingTypeEnum>,
    offSet?: number,
    limit?: number,
    retroreflectionStatus?: boolean,
    status?: Array<PendingStatusEnum>,
    occurrenceStatus?: Array<OccurrenceStatusEnum>,
    files?: boolean,
    supportItensFilter?: Array<any>,
  ) {
    let url = this._urlGetSignaling + '?';
    url = this.createFilterUrl(
      url,
      checkOptionsFilter,
      warrantyStatus,
      signalingTypes,
      offSet,
      limit,
      false,
      retroreflectionStatus,
      status,
      supportItensFilter,
      occurrenceStatus,
      files
    );

    return this.get(url, SignalingList);
  }

  getSignalingSummarized(
    checkOptionsFilter?: Array<any>,
    warrantyStatus?: Array<WarrantyStatusEnum>,
    signalingTypes?: Array<SignalingTypeEnum>,
    supportItensFilter?: Array<any>,
  ) {
    let url = this.environmentEndpoint + this._urlGetSignalingSummarized + '?';
    url = this.createFilterUrl(url, checkOptionsFilter, warrantyStatus, signalingTypes, undefined, undefined, true, undefined, undefined, supportItensFilter);

    return this._http.get(url);
  }

  getSignlingData(
    checkOptionsFilter?: Array<any>,
    warrantyStatus?: Array<WarrantyStatusEnum>,
    signalingTypes?: Array<SignalingTypeEnum>,
    summarized?: boolean,
    retroreflectionStatus?: boolean,
    status?: Array<PendingStatusEnum>,
    supportItensFilter?: Array<any>,
  ) {
    let url = this._urlGetSignalingData + '?';
    url = this.createFilterUrl(
      url,
      checkOptionsFilter,
      warrantyStatus,
      signalingTypes,
      undefined,
      undefined,
      summarized,
      retroreflectionStatus,
      status,
      supportItensFilter
    );

    return this.get(url, SignalingFilterRes);
  }

  getSignlingDataDashboard(contractIdList?: Array<any>, startDate?, endDate?, retroreflectionStatus?) {
    let listJson = JSON.stringify(contractIdList);
    let url = this._urlGetSignalingData + '?' + (contractIdList.length ? `contractIds=${listJson}&` : '');

    if (startDate) url += `startDate=${startDate.toISOString()}&`;

    if (endDate) url += `endDate=${endDate.toISOString()}&`;

    if (retroreflectionStatus) {
      url += `belowMinimumRetroreflection=${retroreflectionStatus}&`;
    }

    return this.get(url, SignalingFilterRes);
  }

  getExpiredSigns() {
    const url = this._urlExpiredSignalings;
    return this.get(url, SignalingList);
  }

  getAllVertical(
    historyBool: boolean = false,
    summarizedBool: boolean = false,
    noArea = false,
    noRegion = false,
    pendencies?: Array<PendingStatusEnum>,
    occurrencesStatus?: Array<OccurrenceStatusEnum>
  ) {
    const history = historyBool ? `historical=true` : '';
    const summarized = summarizedBool ? `summarized=true` : '';
    const status = !pendencies || pendencies.length == 0 ? '' : `?status=${JSON.stringify(pendencies)}`;
    const area = noArea ? `&noArea=true` : '';
    const region = noRegion ? `&noRegion=true` : '';
    const occurrences = !occurrencesStatus || occurrencesStatus.length == 0 ? '' : (pendencies?.length ? '&' : '?') + `occurrenceStatus=${JSON.stringify(occurrencesStatus)}`;
    const url =
      this._urlGetAllVertical + (history || summarized ? '?' : '') + history + summarized + area + region + status + occurrences;
    return this.get(url, VerticalSignaling);
  }

  getVertical(id) {
    const url = this._urlGetAllVertical + `/${id}`;
    return this.get(url, VerticalSignaling);
  }

  getTrafficSign(signId) {
    const url = this._urlGetTrafficSign.replace(':id', signId);
    return this.get(url, TrafficSign);
  }

  putTrafficSign(signId: string, trafficSign: VerticalTrafficSignaling) {
    const url = this._urlGetTrafficSign.replace(':id', signId);
    return this.put(url, trafficSign, VerticalTrafficSignaling);
  }

  getTrafficSignsByArray(signIds: any[]) {
    const url = this._urlGetTrafficSignsByArray.replace(':ids', JSON.stringify(signIds));
    return this.get(url, TrafficSign);
  }

  getHorizontalSignsByArray(signIds: any[]) {
    const url = this._urlGetHorizontalSignsByArray.replace(':ids', JSON.stringify(signIds));
    return this.get(url, HorizontalSignaling);
  }

  getDeviceSignsByArray(signIds: any[]) {
    const url = this._urlGetDeviceSignsByArray.replace(':ids', JSON.stringify(signIds));
    return this.get(url, DeviceSignaling);
  }

  getAllVerticalFromProject(projectId: string) {
    const url = this._urlGetAllVerticalFromProject.replace(':id', projectId);
    return this.get(url, VerticalSignaling);
  }

  getAllHorizontal(
    historyBool: boolean = false,
    summarizedBool: boolean = false,
    noArea = false,
    noRegion = false,
    pendencies?: Array<PendingStatusEnum>,
    occurrencesStatus?: Array<OccurrenceStatusEnum>,
  ) {
    const history = historyBool ? `?historical=true` : '';
    const summarized = summarizedBool ? `summarized=true` : '';
    const status = !pendencies || pendencies.length == 0 ? '' : `?status=${JSON.stringify(pendencies)}`;
    const area = noArea ? `&noArea=true` : '';
    const region = noRegion ? `&noRegion=true` : '';
    const occurrences = !occurrencesStatus || occurrencesStatus.length == 0 ? '' : (pendencies?.length ? '&' : '?') + `occurrenceStatus=${JSON.stringify(occurrencesStatus)}`;
    const url =
      this._urlGetAllHorizontal + (history || summarized ? '?' : '') + history + summarized + area + region + status + occurrences;
    return this.get(url, HorizontalSignaling);
  }

  getAllHorizontalFromProject(projectId: string) {
    const url = this._urlGetAllHorizontalFromProject.replace(':id', projectId);
    return this.get(url, HorizontalSignaling);
  }

  getHorizontal(id) {
    const url = this._urlGetAllHorizontal + `/${id}`;
    return this.get(url, HorizontalSignaling);
  }

  getAllDevices(
    historyBool: boolean = false,
    summarizedBool: boolean = false,
    noArea = false,
    noRegion = false,
    pendencies?: Array<PendingStatusEnum>,
    occurrencesStatus?: Array<OccurrenceStatusEnum>
  ) {
    const history = historyBool ? `?historical=true` : '';
    const summarized = summarizedBool ? `summarized=true` : '';
    const status = !pendencies || pendencies.length == 0 ? '' : `?status=${JSON.stringify(pendencies)}`;
    const area = noArea ? `&noArea=true` : '';
    const region = noRegion ? `&noRegion=true` : '';
    const occurrences = !occurrencesStatus || occurrencesStatus.length == 0 ? '' : (pendencies?.length ? '&' : '?') + `occurrenceStatus=${JSON.stringify(occurrencesStatus)}`;
    const url =
      this._urlGetAllDevices + (history || summarized ? '?' : '') + history + summarized + area + region + status + occurrences;
    return this.get(url, DeviceSignaling);
  }

  getAllDeviceFromProject(projectId: string) {
    const url = this._urlGetAllDevicesFromProject.replace(':id', projectId);
    return this.get(url, DeviceSignaling);
  }

  getDevice(id) {
    const url = this._urlGetAllDevices + `/${id}`;
    return this.get(url, DeviceSignaling);
  }

  getSignalingHistory(action?: ActionEnum, startDate?: Date, endDate?: Date) {
    const start = startDate ? `startDate=${startDate.toISOString()}&` : '';
    const end = endDate ? `endDate=${endDate.toISOString()}&` : '';
    const actionType = action ? `action=${action}` : '';
    const url = this.environmentEndpoint + this._urlGetSignalingHistory + '?' + start + end + actionType;
    return this._http.get(url);
  }

  deleteDevice(id) {
    const url = this._urlGetAllDevices + `/${id}`;
    return this.del(url, DeviceSignaling);
  }

  deleteAllDevice(ids) {
    const idsJson = JSON.stringify(ids);
    const url = this._urlDeleteAllDevices + `?ids=${idsJson}`;
    return this.del(url, DeviceSignaling);
  }

  deleteVertical(id) {
    const url = this._urlGetAllVertical + `/${id}`;
    return this.del(url, VerticalSignaling);
  }

  deleteVerticalCombined(list, id?) {
    const idsJson = JSON.stringify(list);
    const idUrl = id ? `verticalSignalingId=${id}&` : '';
    const url = this._urlDeleteVerticalCombined + '?' + idUrl + `trafficSignIds=${idsJson}`;
    return this.del(url, VerticalSignaling);
  }

  deleteAllVertical(ids) {
    const idsJson = JSON.stringify(ids);
    const url = this._urlDeleteAllVertical + `?ids=${idsJson}`;
    return this.del(url, VerticalSignaling);
  }

  deleteHorizontal(id) {
    const url = this._urlGetAllHorizontal + `/${id}`;
    return this.del(url, HorizontalSignaling);
  }

  deleteAllHorizontal(ids) {
    const idsJson = JSON.stringify(ids);
    const url = this._urlDeleteAllHorizontal + `?ids=${idsJson}`;
    return this.del(url, HorizontalSignaling);
  }

  createVerticalSign(reqBody: VerticalSignaling) {
    const url = this._urlGetAllVertical;
    return this.post(url, reqBody, VerticalSignaling);
  }

  submitVerticalSign(reqBody: any) {
    const url = this.environmentEndpoint + this._urlGetAllVertical;
    return this._http.post(url, reqBody);
  }

  createHorizontalSign(reqBody: HorizontalSignaling) {
    const url = this._urlGetAllHorizontal;
    return this.post(url, reqBody, HorizontalSignaling);
  }

  submitHorizontalSign(reqBody: HorizontalSignaling) {
    const url = this.environmentEndpoint + this._urlGetAllHorizontal;
    return this._http.post(url, reqBody);
  }

  createDeviceSign(reqBody: DeviceSignaling) {
    const url = this._urlGetAllDevices;
    return this.post(url, reqBody, DeviceSignaling);
  }

  submitDeviceSign(reqBody: DeviceSignaling) {
    const url = this.environmentEndpoint + this._urlGetAllDevices;
    return this._http.post(url, reqBody);
  }

  updateCurrentSign(sign) {
    this.currentSign = sign;
  }

  updateVerticalSign(id: string, reqBody) {
    const url = this.environmentEndpoint + this._urlGetAllVertical + `/${id}`;
    return this._http.put(url, reqBody);
  }

  putVerticalSign(id, reqBody: VerticalSignaling) {
    const url = this._urlGetAllVertical + `/${id}`;
    return this.put(url, reqBody, VerticalSignaling);
  }

  updateTrafficSignStatus(listIds: Array<string>, status: string, justification?: string) {
    const idsJson = JSON.stringify(listIds);
    const url =
      this.environmentEndpoint +
      this._urlGetTrafficSign.replace('/:id', '') +
      this._urlUpdateStatus +
      `?trafficSignIds=${idsJson}&status=${status}`;
    return this._http.patch(url, { justification });
  }

  updateHorizontalSign(id: string, reqBody) {
    const url = this.environmentEndpoint + this._urlGetAllHorizontal + `/${id}`;
    return this._http.put(url, reqBody);
  }

  putHorizontalSign(id, reqBody: HorizontalSignaling) {
    const url = this._urlGetAllHorizontal + `/${id}`;
    return this.put(url, reqBody, HorizontalSignaling);
  }

  updateHorizontalStatus(listIds: Array<string>, status: string, justification?: string) {
    const idsJson = JSON.stringify(listIds);
    const url =
      this.environmentEndpoint +
      this._urlGetAllHorizontal +
      this._urlUpdateStatus +
      `?horizontalSignalingsIds=${idsJson}&status=${status}`;
    return this._http.patch(url, { justification });
  }

  updateDeviceSign(id: string, reqBody) {
    const url = this.environmentEndpoint + this._urlGetAllDevices + `/${id}`;
    return this._http.put(url, reqBody);
  }

  updateDeviceStatus(listIds: Array<string>, status: string, justification?: string) {
    const idsJson = JSON.stringify(listIds);
    const url =
      this.environmentEndpoint +
      this._urlGetAllDevices +
      this._urlUpdateStatus +
      `?deviceSignalingsIds=${idsJson}&status=${status}`;
    return this._http.patch(url, { justification });
  }

  putDeviceSign(id, reqBody: DeviceSignaling) {
    const url = this._urlGetAllDevices + `/${id}`;
    return this.put(url, reqBody, DeviceSignaling);
  }

  uploadVerticalFiles(files, signId: string, situation: SignalingImageSituationEnum) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    const situationImg = `?situation=${situation}`;
    const url = this.environmentEndpoint + this._urlUploadFilesVertical.replace(':id', signId) + situationImg;
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  uploadHorizontalFiles(files, signId: string, situation: SignalingImageSituationEnum) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    const situationImg = `?situation=${situation}`;
    const url = this.environmentEndpoint + this._urlHorizontalFilesVertical.replace(':id', signId) + situationImg;
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  uploadDeviceFiles(files, signId: string, situation: SignalingImageSituationEnum) {
    const reqHeaders = new HttpHeaders({
      Authorization: `Bearer ${this._auth.getJwtToken()}`,
      FormData: 'true',
    });
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    const situationImg = `?situation=${situation}`;
    const url = this.environmentEndpoint + this._urlDeviceFilesVertical.replace(':id', signId) + situationImg;
    return this._http.post(url, formData, { headers: reqHeaders });
  }

  // Remove uma por vez
  removeVerticalImage(fileId) {
    const url = this.environmentEndpoint + this._urlRemoveVerticalImage.replace(':fileId', fileId);
    return this.http.delete(url);
  }

  removeHorizontalImage(fileId) {
    const url = this.environmentEndpoint + this._urlRemoveHorizontalImage.replace(':fileId', fileId);
    return this.http.delete(url);
  }

  removeDeviceImage(fileId) {
    const url = this.environmentEndpoint + this._urlRemoveDeviceImage.replace(':fileId', fileId);
    return this.http.delete(url);
  }

  downloadVerticalFiles(id) {
    const url = this.environmentEndpoint + this._urlDownloadVerticalFiles + `/${id}`;

    return this._http.get(url);
  }

  downloadHorizontalFiles(id) {
    const url = this.environmentEndpoint + this._urlDownloadHorizontalFiles + `/${id}`;
    return this._http.get(url);
  }

  downloadDeviceFiles(id) {
    const url = this.environmentEndpoint + this._urlDownloadDeviceFiles + `/${id}`;
    return this._http.get(url);
  }

  getUserSignalignHistory() {
    const url = this.environmentEndpoint + this._urlUserSignalignsHistory;
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 7);
    return this._http.get(url.replace('queryParams', `action=CREATED&startDate=${startDate.toISOString()}`));
  }

  // Verifica permissao para editar a sinalização
  isEditable(itemId): boolean {
    if (this._auth?.currentMember?.role == MemberRoleEnum.TeamLeader) {
      const found = this.editableSigns.find((signId) => {
        return signId == itemId;
      });
      if (found) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setCombinedSigns(trafficSigns: Array<TrafficSign>, groupId, index): Array<VerticalCombinedSign> {
    const combinedSigns: Array<VerticalCombinedSign> = [];
    if (trafficSigns.length > 1) {
      trafficSigns.forEach((conjugate, i) => {
        if (index != i) {
          const conjugateAux: VerticalCombinedSign = new VerticalCombinedSign({
            id: conjugate.id,
            groupId,
            svg: svgElement.Signaling[conjugate.signCode],
            order: conjugate.order,
            signCode: conjugate.signCode,
            internalCaption: conjugate.internalCaption,
          });
          combinedSigns.push(conjugateAux);
        }
      });
    }
    return combinedSigns;
  }

  // Emite o memorial de calculo geral
  getCalculationMemoryReport(
    startDate: Date,
    endDate: Date,
    device: boolean,
    horizontal: boolean,
    vertical: boolean,
    financial: boolean,
  ) {
    const url = this.environmentEndpoint + this._urlMemorial;
    const params = new HttpParams().appendAll({
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      deviceSignaling: device,
      horizontalSignaling: horizontal,
      verticalSignaling: vertical,
      financial: financial,
    });
    return this.http.get(url, { params: params });
  }

  // Retorna sinalizações de uma trecho vinculadas a um trajeto
  getPathSignaligns(pathId: string, startsAtGreaterThan: number, endsAtLessThan: number) {
    const url = this.environmentEndpoint + this._urlGetSignaling;
    const params = new HttpParams().appendAll({
      pathId: pathId,
      startsAtGreaterThan: startsAtGreaterThan,
      endsAtLessThan: endsAtLessThan
    })

    return this.http.get(url, { params: params });
  }

  // Usando o mesmo endpoint só que no mapa e com muitas querys
  getDashboardMapCatalog(checkOptionsFilter?: Array<any>,
    warrantyStatus?: Array<WarrantyStatusEnum>,
    signalingTypes?: Array<SignalingTypeEnum>,
  ) {
    let url = this.environmentEndpoint + this._urlDashboardCatalog + '?';
    url = this.createFilterUrl(url, checkOptionsFilter, warrantyStatus, signalingTypes, undefined, undefined, true);

    return this.http.get(url)
  }
}
