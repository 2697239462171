<app-header></app-header>
<app-header-menu></app-header-menu>
<app-toggle-sidebar></app-toggle-sidebar>
<div class="container-fluid flex-row main">
  <div class="d-flex row justify-content-between action-bar px-3">
    <h1>Projetos</h1>
  </div>

  <!-- Linha de Botões no Cadastro -->
  <div class="contentLinks mt-4" *ngIf="!editMode && !loading">
    <div class="col-auto px-0 d-flex align-items-center ">
      <i class="fas fa-arrow-left cursor-pointer fa-lg" (click)="emitPageController(signTypeEnum.Vertical)"></i>
      <h2 style="font-weight:600; margin-left: 16px;">{{ projectBody?.name }}</h2>
    </div>
    <div class="row mx-0 w-100 d-flex flex-row justify-content-end align-center">
      <div class="input-row">
        <div class="label-contract font-weight-bold">Valor Total do Projeto:</div>
        <div class="project-value">{{projectBody?.cost | currency:'BRL':'R$'}}</div>
      </div>
      <button class="btn btn-outline-primary mx-4" (click)="openModal(closeWarning)"
        style="width: 270px;">cancelar</button>
      <button class="btn btn-primary mr-1" (click)="submitProject()" [disabled]="blockSubmit"
        style="width: 270px;">finalizar</button>
    </div>
  </div>
  <!-- Linha de Botões no Cadastro -->


  <!-- Linha de Botões na Edição -->
  <div class="contentLinks mt-4 mr-3" *ngIf="editMode && !loading">
    <div class="col-auto px-0 d-flex align-items-center ">
      <i class="fas fa-arrow-left cursor-pointer fa-lg" routerLink="/projects"></i>
      <h2 style="font-weight:600; margin-left: 16px;">{{ projectBody?.name }}</h2>
    </div>
    <div class="row mx-0 w-100 d-flex flex-row justify-content-end align-center">
      <div class="input-row">
        <div class="label-contract font-weight-bold">Valor Total do Projeto:</div>
        <div class="project-value">{{projectBody?.cost | currency:'BRL':'R$'}}</div>
      </div>
      <button class="btn btn-outline-primary mx-4" (click)="openLargeModal(sendToTaskModal)">Enviar para
        serviços</button>
      <button class="btn btn-outline-primary mr-4" (click)="goToEdition()">
        editar
      </button>
      <button class="btn btn-outline-primary" (click)="openModal(removeProject)">
        remover
      </button>
    </div>
  </div>
  <!-- Linha de Botões na Edição -->

  <div *ngIf="loading" style="position: fixed; top: 50%; left: 48%;">
    <app-loading-page></app-loading-page>
  </div>

  <!-- Informações do Projeto -->
  <div class="project-infos" *ngIf="!loading">

    <!-- Side box com as infos do usuario criador -->
    <div class="col-2">
      <div class="cardInformations">

        <!-- Criado Por -->
        <div class="side-data py-3">
          <div class="col px-0">
            <h3 style="font-weight: 500;" class="mb-2">Criado por:</h3>
            <img style="width: 38px; border-radius: 50%;" class="mb-2"
              [src]="projectBody?.createdByMember?.user?.imageId ? projectBody?.createdByMember?.user?.imageURL : (currentUser?.imageId && !projectBody?.id) ? currentUser?.imageURL : 'assets/icons/camera.svg' "
              height="40px" width="24px" alt="" srcset="">
            <p style="font-weight: 500">{{projectBody?.id ? projectBody?.createdByMember?.user?.firstName+'
              '+projectBody?.createdByMember?.user?.lastName : currentUser?.firstName + ' ' + currentUser?.lastName}}
            </p>
          </div>
        </div>
        <!-- Criado Por -->


        <!-- Data de Criação  -->
        <div class="side-data">
          <div class="px-0 d-flex flex-column h-100">
            <small style="font-weight: 500;" class="text-muted my-2">Data de Criação</small>
            <p>{{projectBody?.createdAt | date: 'dd/MM/yyyy'}}</p>
          </div>
        </div>
        <!-- Data de Criação  -->


        <!-- Regiões Áreas e Trajetos  -->
        <div class="side-data py-2">
          <div class="col px-0">

            <!-- Regiões -->
            <div style="font-weight: 500; font-size: 14px;" class="text-muted">Região</div>
            <p *ngFor="let region of projectBody?.regions">{{region?.name}}</p>
            <p *ngIf="!projectBody?.regions?.length">Sem regiões</p>
            <!-- Regiões -->


            <!-- Áreas -->
            <div style="font-weight: 500; font-size: 14px;" class="text-muted mt-2">Área</div>
            <p *ngFor="let area of projectBody?.areas">{{area?.name}}</p>
            <p *ngIf="!projectBody?.areas?.length">Sem áreas</p>
            <!-- Áreas -->


            <!-- Trajetos -->
            <div style="font-weight: 500; font-size: 14px;" class="text-muted mt-2">Trajeto</div>
            <p *ngFor="let path of projectBody?.paths">{{path?.name}}</p>
            <p *ngIf="!projectBody?.paths?.length">Sem trajetos</p>
            <!-- Trajetos -->
          </div>
        </div>
        <!-- Regiões Áreas e Trajetos  -->


        <!-- Arquivo Mestre  -->
        <div class="side-data py-3 files-overflow">
          <small style="font-weight: 500;" class="text-muted">Arquivo Mestre</small>
          <div class="scroll-files">
            <ng-container *ngFor="let file of uploadedFiles">
              <p class="my-1 cursor-pointer w-100 text-primary" *ngIf="file?.masterFile"
                (click)="downloadFile(file?.id)"><i class="fad fa-paperclip-vertical"></i> {{file?.file?.originalName ||
                file?.file?.name}}</p>
            </ng-container>
          </div>
        </div>
        <!-- Arquivo Mestre  -->


        <!-- Arquivos Anexados  -->
        <div class="side-data py-3 files-overflow">
          <small style="font-weight: 500;" class="text-muted">Arquivos Anexados</small>
          <div class="scroll-files">
            <p *ngFor="let file of uploadedFiles" class="my-1 cursor-pointer w-100 text-primary"
              (click)="downloadFile(file?.id)"><i class="fad fa-paperclip-vertical"></i> {{file?.file?.originalName ||
              file?.file?.name}}</p>
            <p *ngIf="!uploadedFiles || uploadedFiles?.length < 1" class="text-muted w-100 pt-3">Nenhum arquivo anexado
            </p>
          </div>
        </div>
        <!-- Arquivos Anexados  -->


        <!-- Observações  -->
        <div class="side-data py-3 files-overflow" style="border: none;">
          <small style="font-weight: 500;" class="text-muted">Observações</small>
          <div class="scroll-files">
            <p class="text-muted w-100 pt-3">{{projectBody?.observation || 'Nenhuma Observação'}}
            </p>
          </div>
        </div>
        <!-- Observações  -->

      </div>
    </div>
    <!-- Side box com as infos do usuario criador -->


    <!-- Informações do Projeto -->
    <div>

      <!-- Descrição -->
      <h3 class="section-title">
        Descrição do Projeto
      </h3>

      <div class="description-box">
        <span>
          {{projectBody?.description || 'Sem descrição'}}
        </span>
      </div>
      <!-- Descrição -->


      <!-- Box de cada sinalização HVD e Suporte -->
      <h3 class="section-title">
        Sinalizações do Projeto
      </h3>

      <!-- Box de Verticais e Suporte -->
      <ng-container *ngIf="projectBody?.projectTrafficSigns?.length || projectBody?.projectSupports?.length">

        <h3 class="type-title">
          Vertical
        </h3>

        <app-project-card *ngIf="projectBody?.projectTrafficSigns?.length" [type]="signTypeEnum.Vertical"
          [contract]="contract" [list]="projectBody?.projectTrafficSigns">
        </app-project-card>

        <app-project-card *ngIf="projectBody?.projectSupports?.length" [type]="verticalGroupTypeEnum.Support"
          [contract]="contract" [list]="projectBody?.projectSupports">
        </app-project-card>
      </ng-container>

      <!-- Box de Verticais e Suporte -->


      <!-- Box de Horizontais-->
      <ng-container *ngIf="projectBody?.projectHorizontalSignalings.length">
        <h3 class="type-title">
          Horizontal
        </h3>
        <app-project-card [type]="signTypeEnum.Horizontal" [contract]="contract"
          [list]="projectBody?.projectHorizontalSignalings">
        </app-project-card>
      </ng-container>
      <!-- Box de Horizontais-->


      <!-- Box de Dispositivos -->
      <ng-container *ngIf="projectBody?.projectDeviceSignalings.length">
        <h3 class="type-title">
          Dispositivos
        </h3>
        <app-project-card [type]="signTypeEnum.Device" [contract]="contract"
          [list]="projectBody?.projectDeviceSignalings"></app-project-card>
      </ng-container>
      <!-- Box de Dispositivos -->


      <!-- Box de cada sinalização HVD e Suporte -->

    </div>
    <!-- Informações do Projeto -->


    <!-- Visualização Depois de Criada -->
    <div class="map-master-history pr-3" *ngIf="displayPage">

      <!-- Mapa -->
      <div class="map-master-history">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="text-primary"> Mapa </h3>
          <a class="btn btn-link btn-popup details-btn" (click)="openLargeModal(mapModal); createMapModal()">
            MAIS DETALHES
          </a>
        </div>
        <div id="project-map"> </div>
      </div>
      <!-- Mapa -->


      <!-- Arquivo Mestre -->
      <div class="map-master-history">
        <div class="d-flex align-items-center justify-content-between">
          <h3 class="text-primary"> Arquivo Mestre </h3>
          <a class="btn btn-link btn-popup details-btn" [class.disabled]="!masterFile"
            (click)="openLargeModal(masterFileModal); createMapModal()">
            MAIS DETALHES
          </a>
        </div>
        <div class="master-file-edit mt-0" id="view-master-on-resume"
          style="border-radius: 16px !important; height: 350px;">
          <ng-container *ngIf="masterFile; else elseMasterFile">
            <img class="w-100" [src]="masterFile" id="view-master-on-resume" #viewMasterOnResume>
            <img [src]="point.svg" height="38px" width="38px" class="position-absolute" *ngFor="let point of pointsList"
              [style]="GetMarkerPosition(viewMasterOnResume, point)">
          </ng-container>
        </div>
        <ng-template #elseMasterFile>
          <div class="no-master-file">
            Nenhum arquivo-mestre selecionado.
            Para anexar, edite o projeto e adicione
            um arquivo de imagem compatível.
          </div>
        </ng-template>
      </div>
      <!-- Arquivo Mestre -->


      <!-- Histórico -->
        <h3 class="text-primary"> Histórico do Projeto </h3>
        <div class="my-3">
          <table class="history-list">
            <tr *ngFor="let item of projectBody?.history">
              <!-- Caixa do histórico -->
              <div class="history-box">

                <!-- Horario de criação -->
                <div class="date-created">
                  {{item.createdAt | date: 'dd/MM/yyyy HH:mm' }}
                </div>
                <!-- Horario de criação -->

                <!-- Informações do Histórico -->
                <div class="center-row">
                  <!-- Título da Ação -->
                  <div class="mb-2">
                    <i class='mr-2 fa fa-lg'
                      [ngClass]="{'fa-star': item?.action == actionEnum.Created, 'fa-pen': item?.action == actionEnum.Edited, 'fa-do-not-enter': item?.action == actionEnum.Removed, 'fa-circle-half-stroke': item?.action == actionEnum.Retroreflection}"
                      style="height: 24px;"></i>
                    <span style="font-weight: 500;">{{item['action'] | enumText: 'ProjectHistoryAction'}}</span>
                  </div>
                  <!-- Título da Ação -->
                  <!-- Responsável -->
                  <div class="d-flex mr-2 mb-2 flex-column">
                    <span class="action-title">Responsável:</span>
                    <span>
                      {{item?.member?.user?.firstName + ' ' + item?.member?.user?.lastName}}
                    </span>
                  </div>
                  <!-- Responsável -->


                  <!-- Ação Executada -->
                  <div class="d-flex mr-2 flex-column">
                    <span class="action-title">Ação executada:
                    </span>
                    <span>{{item['action'] == actionEnum.Created ? ('Usuário(a) criou este projeto')
                      : item['action'] == actionEnum.Edited ? ('Usuário(a) editou os parâmetros desse projeto')
                      : item['action'] == actionEnum.Removed
                      ? ('Este projeto foi removido.')
                      : ''}} </span>

                  </div>
                  <!-- Ação Executada -->

                </div>
                <!-- Informações do Histórico -->

              </div>
              <!-- Caixa do histórico -->

            </tr>
          </table>
        </div>

      <!-- Histórico -->

    </div>
    <!-- Visualização Depois de Criada -->

  </div>
</div>

<!-- Modal para encerrar ação - Aviso de perda de progresso -->
<ng-template #closeWarning>
  <div class="modal-header">
    <h3 class="modal-title">Cancelar {{editMode ? 'Edição' : 'Criação'}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <i aria-hidden="true" class="fas fa-xmark"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="h2">Tem certeza que deseja sair da {{editMode ? 'edição' : 'criação'}} deste Projeto?</div>
  </div>

  <div class="modal-footer">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">
          cancelar
        </button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-primary" routerLink="/projects" (click)="modalRef.hide()">
          sair
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- Aviso de remoção -->
<ng-template #removeProject>
  <div class="modal-header">
    <h3 class="modal-title">Remover Projeto</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <i aria-hidden="true" class="fas fa-xmark"></i>
    </button>
  </div>

  <div class="modal-body">
    <div class="h3">Tem certeza que deseja remover o projeto?</div>
  </div>

  <div class="modal-footer">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button type="button" class="btn btn-outline-primary" (click)="modalRef.hide()">
          cancelar
        </button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-primary bg-danger" style="border: none" (click)="deleteProject()">
          remover
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sendToTaskModal>
  <div class="modal-header" style="align-items: center;">
    <h3 class="modal-title"><i class="fa-regular fa-square-plus fa-lg mr-1"></i> Implantação - Mandar Sinalizações
      para Serviços</h3>
    <button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
      <i aria-hidden="true" class="fas fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mx-0" style="min-height: 550px;">
      <div class="col px-0 mr-2">
        <p class="m-0">As sinalizações abaixo estão separadas por seu tipo de sinalização. Escolha as opções ao lado
          para
          destinar estas sinalizações para Serviços.</p>
        <div class="sign-modal-scroll">
          <h3 class="text-primary my-4">Vertical</h3>
        <div class="d-flex justify-content-between align-items-center mb-2"
          *ngFor="let taskSign of modalSignList.projectTrafficSigns; index as indexOfTaskSign">
          <div class="sign-tile mr-1">
            <div class="col-auto px-0 mr-4">
              <img [src]="taskSign.verticalCode | enumSvg: 'Signaling'" alt="" height="38px" width="38px" style="object-fit: contain;">
            </div>
            <div class="col px-0 my-2">
              <p class="mb-2">{{taskSign.verticalCode | enumText: 'Signaling'}}</p>
              <div class="d-flex align-items-center mr-2">
                <div class="type-box" style="background-color: var(--snow-dark)">
                  <p class="type-text" style="color: var(--gray-dark);">{{taskSign?.dimensionType}}</p>
                </div>
                <p class="m-0"><i class="fa-solid fa-arrows-v mr-1 text-primary"></i>{{taskSign.dimensionX}} m <i
                    class="fa-solid fa-arrows-h mx-1 text-primary"></i>{{taskSign.dimensionY}} m <i
                    class="fa-solid fa-arrows mx-1 text-primary"></i>{{taskSign.dimension}} m²</p>
              </div>
            </div>
            <div class="col-auto px-0">
              <p class="m-0 text-muted">Disponíveis</p>
              <h4>{{taskSign.quantity - (taskSign.quantityRegistered || 0)}}</h4>
            </div>
          </div>
          <button class="btn btn-icon btn-icon-alt mx-2"
            (click)="removeSignFromModal(modalSignList.projectTrafficSigns , indexOfTaskSign)">
            <i class="fas fa-xmark fa-lg text-danger"></i>
          </button>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-2"
          *ngFor="let taskSign of modalSignList.projectSupports; index as indexOfTaskSign">
          <div class="sign-tile mr-1">
            <div class="col-auto px-0 mr-4">
              <img [src]="taskSign.contractVerticalItem.supportMaterial | enumSvg: 'Signaling'" alt="" height="38px"
                width="38px">
            </div>
            <div class="col px-0 my-2">
              <p class="mb-2">{{taskSign.contractVerticalItem.supportMaterial | enumText: 'Signaling'}}</p>
            </div>
            <div class="col-auto px-0">
              <p class="m-0 text-muted">Disponíveis</p>
              <h4>{{taskSign.quantity - (taskSign.quantityRegistered || 0)}}</h4>
            </div>
          </div>
          <button class="btn btn-icon btn-icon-alt mx-2"
            (click)="removeSignFromModal(modalSignList.projectSupports , indexOfTaskSign)">
            <i class="fas fa-xmark fa-lg text-danger"></i>
          </button>
        </div>
        <h3 class="text-primary my-4">Horizontal</h3>
        <div class="d-flex justify-content-between align-items-center mb-2"
          *ngFor="let taskSign of modalSignList.projectHorizontalSignalings; index as indexOfTaskSign">
          <div class="sign-tile mr-1">
            <div class="col-auto px-0 mr-4">
              <img [src]="taskSign.horizontalCode | enumSvg: 'Signaling'" alt="" height="38px" width="38px">
            </div>
            <div class="col px-0 my-2">
              <p class="mb-2">{{taskSign.horizontalCode | enumText: 'Signaling'}}</p>
              <div class="d-flex align-items-center mr-2">
                <div class="type-box" style="background-color: var(--snow-dark)">
                  <p class="type-text" style="color: var(--gray-dark);">{{taskSign?.dimensionType}}</p>
                </div>
                <p class="m-0"><i class="fa-solid fa-arrows-v mr-1 text-primary"></i>{{taskSign.dimensionX}} m <i
                    class="fa-solid fa-arrows-h mx-1 text-primary"></i>{{taskSign.dimensionY}} m <i
                    class="fa-solid fa-arrows mx-1 text-primary"></i>{{taskSign.dimension}} m²</p>
              </div>
            </div>
            <div class="col-auto px-0">
              <p class="m-0 text-muted">Disponíveis</p>
              <h4>{{taskSign.quantity - (taskSign.quantityRegistered || 0)}}</h4>
            </div>
          </div>
          <button class="btn btn-icon btn-icon-alt mx-2"
            (click)="removeSignFromModal(modalSignList.projectHorizontalSignalings , indexOfTaskSign)">
            <i class="fas fa-xmark fa-lg text-danger"></i>
          </button>
        </div>
        <h3 class="text-primary my-4">Dispositivos</h3>
        <div class="d-flex justify-content-between align-items-center mb-2"
          *ngFor="let taskSign of modalSignList.projectDeviceSignalings; index as indexOfTaskSign">
          <div class="sign-tile mr-1">
            <div class="col-auto px-0 mr-4">
              <img [src]="taskSign.deviceCode | enumSvg: 'Signaling'" alt="" height="38px" width="38px">
            </div>
            <div class="col px-0 my-2">
              <p class="mb-2">{{taskSign.deviceCode | enumText: 'Signaling'}}</p>
              <p class="m-0"><i class="fa-solid fa-bring-forward mr-1 text-primary"></i>{{taskSign.units}}
                <i class="fa-solid fa-arrows-h ml-2 mr-1 text-primary"></i>{{taskSign.dimension}} m
              </p>
            </div>
            <div class="col-auto px-0">
              <p class="m-0 text-muted">Disponíveis</p>
              <h4>{{taskSign.quantity - (taskSign.quantityRegistered || 0)}}</h4>
            </div>
          </div>
          <button class="btn btn-icon btn-icon-alt mx-2"
            (click)="removeSignFromModal(modalSignList.projectDeviceSignalings , indexOfTaskSign)">
            <i class="fas fa-xmark fa-lg text-danger"></i>
          </button>
        </div>
        </div>
      </div>
      <div class="col-4 px-0">
        <div class="custom-control custom-radio mb-3">
          <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input"
            (change)="modalSelectedOption = 0" checked>
          <label class="custom-control-label" for="customRadio1">Criar nova Ordem de Serviço</label>
        </div>
        <div class="custom-control custom-radio mb-3" (change)="modalSelectedOption = 1">
          <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
          <label class="custom-control-label" for="customRadio2">Criar nova Tarefa Independente</label>
        </div>
        <div class="ml-4 mb-3 d-flex align-items-center justify-content-between">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheckVertical"
              [disabled]="modalSelectedOption != 1 || !modalSignList.projectTrafficSigns.length"
              [checked]="modalCheckState.vertical" (change)="modalCheckState.vertical = !modalCheckState.vertical">
            <label class="custom-control-label" for="customCheckVertical">Vertical</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheckHorizontal"
              [checked]="modalCheckState.horizontal"
              [disabled]="modalSelectedOption != 1 || !modalSignList.projectHorizontalSignalings.length"
              (change)="modalCheckState.horizontal = !modalCheckState.horizontal">
            <label class="custom-control-label" for="customCheckHorizontal">Horizontal</label>
          </div>
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="customCheckDevice"
              [disabled]="modalSelectedOption != 1 || !modalSignList.projectDeviceSignalings.length"
              [checked]="modalCheckState.device" (change)="modalCheckState.device = !modalCheckState.device">
            <label class="custom-control-label" for="customCheckDevice">Dispositivos</label>
          </div>
        </div>
        <div class="custom-control custom-radio mb-3">
          <input type="radio" id="customRadio3" name="customRadio" class="custom-control-input"
            (change)="modalSelectedOption = 2">
          <label class="custom-control-label" for="customRadio3">Mover sinalizações para uma O.S. existente</label>
        </div>
        <small class="text-muted">O sistema irá criar tarefas automáticas dentro da ordem de serviço selecionada
          abaixo.</small>
        <select name="serviceOrderId" id="serviceOrderId" class="form-control"
          (change)="selectedServiceOrderId = $event.target.value" [disabled]="modalSelectedOption != 2">
          <option value="" hidden>Selecione a Ordem de Serviço</option>
          <option [value]="order.id" *ngFor="let order of serviceOrderList">{{order.name}}</option>

        </select>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-outline-primary" (click)="modalRef.hide()">
      <h4 style="font-weight: 600;">CANCELAR</h4>
    </button>
    <button class="btn btn-primary" (click)="sendToTask(); modalRef.hide()">
      <h4 style="font-weight: 600;">Enviar</h4>
    </button>
  </div>
</ng-template>


<!-- Modal para encerrar ação - Aviso de perda de progresso -->
<ng-template #mapModal>
  <div class="modal-header">
    <h3 class="modal-title">Mapa do Projeto</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <i aria-hidden="true" class="fas fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body px-0">
    <div class="modal-sign-list">
      <!-- Lista de Item de Verticais e Suportes-->
      <ng-container *ngIf="projectBody.projectTrafficSigns.length || projectBody.projectSupports.length ">
        <div class="modal-type-column">
          <h3 class="text-muted"> Vertical </h3>
          <!-- Traffic -->
          <div class="modal-type-list">
            <ng-container *ngFor="let traffic of projectBody.projectTrafficSigns">
              <app-project-card-modal [selectedSign]="modalSignFilterCode"
                (signCodeEmitter)="handleSignCodeEmit($event, true, 'vertical')" [sign]="traffic"
                [signCode]="traffic?.verticalCode" [type]="signTypeEnum.Vertical">
              </app-project-card-modal>
            </ng-container>

            <!-- Suporte -->
            <ng-container *ngFor="let support of projectBody.projectSupports">
              <app-project-card-modal [sign]="support" [signCode]="support?.contractVerticalItem?.supportMaterial"
                [type]="verticalGroupTypeEnum.Support">
              </app-project-card-modal>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Lista de Item de Horizontais -->
      <ng-container *ngIf="projectBody.projectHorizontalSignalings.length">
        <div class="modal-type-column">
          <h3 class="text-muted"> Horizontal </h3>
          <div class="modal-type-list">
            <ng-container *ngFor="let horizontal of projectBody.projectHorizontalSignalings">
              <app-project-card-modal [selectedSign]="modalSignFilterCode"
                (signCodeEmitter)="handleSignCodeEmit($event, true, 'horizontal')" [sign]="horizontal"
                [signCode]="horizontal.horizontalCode" [type]="signTypeEnum.Horizontal"></app-project-card-modal>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <!-- Lista de Item de Dispositivos -->
      <ng-container *ngIf="projectBody.projectDeviceSignalings.length">
        <div class="modal-type-column">
          <h3 class="text-muted"> Dispositivo </h3>
          <div class="modal-type-list">
            <ng-container *ngFor="let device of projectBody.projectDeviceSignalings">
              <app-project-card-modal [selectedSign]="modalSignFilterCode"
                (signCodeEmitter)="handleSignCodeEmit($event, true, 'device')" [sign]="device"
                [signCode]="device.deviceCode" [type]="signTypeEnum.Device">
              </app-project-card-modal>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div id="project-modal-map"></div>
  </div>
</ng-template>

<!-- Modal para encerrar ação - Aviso de perda de progresso -->
<ng-template #masterFileModal>
  <div class="modal-header">
    <h3 class="modal-title">Arquivo-Mestre do Projeto</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">
      <i aria-hidden="true" class="fas fa-xmark"></i>
    </button>
  </div>
  <div class="modal-body px-0">
    <div class="modal-sign-list">
      <!-- Lista de Item de Verticais e Suportes-->
      <ng-container *ngIf="projectBody.projectTrafficSigns.length || projectBody.projectSupports.length ">
        <div class="modal-type-column">
          <h3 class="text-muted"> Vertical </h3>
          <div class="modal-type-list">
            <!-- Traffic -->
            <ng-container *ngFor="let traffic of projectBody.projectTrafficSigns">
              <app-project-card-modal [selectedSign]="modalSignFilterCode" [sign]="traffic"
                [signCode]="traffic?.verticalCode" [type]="signTypeEnum.Vertical"
                (signCodeEmitter)="handleSignCodeEmit($event)">
              </app-project-card-modal>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- Lista de Item de Horizontais -->
      <ng-container *ngIf="projectBody.projectHorizontalSignalings.length">
        <div class="modal-type-column">
          <h3 class="text-muted"> Horizontal </h3>
          <div class="modal-type-list">
            <ng-container *ngFor="let horizontal of projectBody.projectHorizontalSignalings">
              <app-project-card-modal [selectedSign]="modalSignFilterCode" [sign]="horizontal"
                (signCodeEmitter)="handleSignCodeEmit($event)" [signCode]="horizontal.horizontalCode"
                [type]="signTypeEnum.Horizontal"></app-project-card-modal>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <!-- Lista de Item de Dispositivos -->
      <ng-container *ngIf="projectBody.projectDeviceSignalings.length">
        <div class="modal-type-column">
          <h3 class="text-muted"> Dispositivo </h3>
          <div class="modal-type-list">
            <ng-container *ngFor="let device of projectBody.projectDeviceSignalings">
              <app-project-card-modal [selectedSign]="modalSignFilterCode" [sign]="device"
                (signCodeEmitter)="handleSignCodeEmit($event)" [signCode]="device.deviceCode"
                [type]="signTypeEnum.Device">
              </app-project-card-modal>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="master-file-edit" id="master-file-edit">
      <img class="w-100" [src]="masterFile" id="viewMaster" #viewMaster>
      <ng-container *ngFor="let point of pointsList">
        <img [src]="point.svg" height="38px" width="38px"
          *ngIf="modalSignFilterCode ? modalSignFilterCode == point.signCode : true" class="position-absolute"
          [style]="GetMarkerPosition(viewMaster, point)">
      </ng-container>
    </div>
  </div>

</ng-template>
