import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private _auth: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this._auth.isLoggedIn()) {
      //Requisições Externas
      const hostnameDefault = environment.endpoint().split('//')[1].split('/')[0];
      const hostnameRequest = request.url.split('//')[1].split('/')[0];

        //Manipular Cabeçalho apenas de requisições do Hellius
      if (hostnameDefault == hostnameRequest) {
        if (request.headers.get('FormData') == 'true') {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this._auth.getJwtToken()}`,
            },
          });
        } else {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this._auth.getJwtToken()}`,
              'Content-Type': 'application/json',
            },
          });
        }
      }
    }

    return next.handle(request).pipe(
      tap(
        () => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            this.router.navigate(['login']);
          }
        },
      ),
    );
  }
}
