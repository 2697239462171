export enum ActionEnum {
  Created = 'CREATED',
  Edited = 'EDITED',
  Removed = 'REMOVED',
  EditedRemoved = 'EDITED_REMOVED',
  Addition = 'ADDITION',
  Refund = 'REFUND',
  Maintenance = 'MAINTENANCE',
  Replacement = 'REPLACEMENT',
  Retroreflection = 'RETROREFLECTION',
  Approved = 'APPROVED',
  Denied = 'DENIED',
}

export enum SignalingHistoryActionMadeOnEnum {
  Web = 'WEB',
  App = 'APP',
  System = 'SYSTEM'
}

export enum TaskActionEnum {
  Created = 'CREATED',
  Edited = 'EDITED',
  ToStart = 'TO_START',
  Started = 'STARTED',
  Paused = 'PAUSED',
  Completed = 'COMPLETED',
  Canceled = 'CANCELED',
};

export enum RegisterCatalogEnum {
  Created = 'CREATED',
  Edited = 'EDITED',
  Detail = 'DETAIL',
};

export enum SignalingImageSituationEnum {
  Before = 'BEFORE',
  After = 'AFTER',
};

export enum WarrantyStatusEnum {
  UnderWarranty = 'UNDER_WARRANTY',
  EndWarranty = 'END_OF_WARRANTY',
  ExpiredWarranty = 'EXPIRED_WARRANTY',
};

export enum SignalingStatusEnum {
  Approved = 'APPROVED',
  Removed = 'REMOVED',
  Paths = 'PATHS',
  WorkInProgress = 'WORK_IN_PROGRESS'
}

export enum LicenseTypeEnum {
  Light = 'LIGHT',
  Full = 'FULL',
  Dev = 'DEV',
}

export enum ItemUnitEnum {
  und = 'und',
  m2 = 'm²',
  m = 'm',
  hora = 'hora',
  ponto = 'ponto',
  kg = 'kg',
};

export enum GroupPermissionEnum  {
  Admin = 'ADMINISTRATOR',
  Participant = 'PARTICIPANT',
  Both = 'BOTH',
}

export enum WarrantyTypeEnum {
  FixedExpiration = 'FIXED_EXPIRATION',
  TimeInterval = 'TIME_INTERVAL',
};
